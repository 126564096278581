<template>
    <input ref="elRef" :class="inputClass" :readonly="readonly" @input="onInput" @focus="onFocus" @blur="onBlur"
        @keydown="onKeyDown" @keypress="onKeyPress" @paste="onPaste" v-bind="$attrs"/>
</template>

<script setup>
import { ref, computed, onMounted, onUpdated } from 'vue';

const props = defineProps({
    modelValue: null,
    slotChar: {
        type: String,
        default: '_'
    },
    mask: {
        type: String,
        default: null
    },
    autoClear: {
        type: Boolean,
        default: true
    },
    normalizeOnBlur: {
        type: Boolean,
        default: false
    },
    unmask: {
        type: Boolean,
        default: false
    },
    readonly: {
        type: Boolean,
        default: false
    },
    skipUpdates: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'keydown', 'complete', 'keypress', 'paste']);

// Non refs init
let tests = null;
let partialPosition = null;
let len = null;
let firstNonMaskPos = null;
let defs = null;
let androidChrome = null;

let caretTimeoutId = null;
let focusText = null;


let buffer = null;
let defaultBuffer = null;

let oldVal = null;
let lastRequiredNonMaskPos = null;

const DomHandler = {
    getUserAgent() {
        return window.navigator.userAgent;
    },
};

// Refs

const elRef = ref(null);
const focus = ref(false);
const isValueChecked = ref(false);

// Methods
function onInput(event) {
    if (androidChrome) {
        handleAndroidInput(event);
    }
    else {
        handleInputChange(event);
    }
    emit('update:modelValue', event.target.value);
}

function onFocus(event) {
    if (event && event.relatedTarget) {
        if (event.relatedTarget.type === 'submit') {
            return;
        }
    }
    if (props.readonly) {
        return;
    }
    focus.value = true;
    window.clearTimeout(caretTimeoutId);
    let pos;
    focusText = elRef.value.value;
    pos = checkVal();
    caretTimeoutId = setTimeout(() => {
        if (elRef.value !== document.activeElement) {
            return;
        }
        writeBuffer();
        const parsedMask = props.mask.replace(/\\(.)/g, '$1');
        if (pos === parsedMask.replace('?', '').length) {
            caret(0, pos);
        } else {
            caret(pos);
        }
    }, 10);
    emit('focus', event);
}

function onBlur(event) {
    if (elRef.value == null) { return; }
    focus.value = false;
    checkVal();
    if (props.normalizeOnBlur) {
        normalizeModel(event);
    } else {
        updateModel(event);
    }
    if (elRef.value.value !== focusText) {
        let e = new Event('change', {
            bubbles: true,
            cancelable: false
        });
        elRef.value.dispatchEvent(e);
    }
    emit('blur', event);
}

function onKeyDown(event) {
    if (props.readonly) { return; }
    let k = event.which || event.keyCode;
    let pos, begin, end;
    let iPhone = /iphone/i.test(DomHandler.getUserAgent());
    oldVal = elRef.value.value;
    //backspace, delete, and escape get special treatment
    if (k === 8 || k === 46 || (iPhone && k === 127)) {
        pos = caret();
        begin = pos.begin;
        end = pos.end;
        if (end - begin === 0) {
            begin = k !== 46 ? seekPrev(begin) : (end = seekNext(begin - 1));
            end = k === 46 ? seekNext(end) : end;
        }
        clearBuffer(begin, end);
        shiftL(begin, end - 1);
        updateModel(event);
        event.preventDefault();
    } else if (k === 13) {
        // enter
        elRef.value.blur();
        updateModel(event);
    } else if (k === 27) {
        // escape
        elRef.value.value = focusText;
        caret(0, checkVal());
        updateModel(event);
        event.preventDefault();
    }
    emit('keydown', event);
}

function onKeyPress(event) {
    if (props.readonly) { return; }
    let k = event.which || event.keyCode;
    let pos = caret();
    let p, c, next, completed;
    if (event.ctrlKey || event.altKey || event.metaKey || k < 32) {
        //Ignore
        return;
    } else if (k && k !== 13) {
        if (pos.end - pos.begin !== 0) {
            clearBuffer(pos.begin, pos.end);
            shiftL(pos.begin, pos.end - 1);
        }
        p = seekNext(pos.begin - 1);
        if (p < len) {
            c = String.fromCharCode(k);
            if (tests[p].test(c)) {
                shiftR(p);
                buffer[p] = c;
                writeBuffer();
                next = seekNext(p);
                if (/android/i.test(DomHandler.getUserAgent())) {
                    //Path for CSP Violation on FireFox OS 1.1
                    let proxy = () => {
                        caret(next);
                    };
                    setTimeout(proxy, 0);
                } else {
                    caret(next);
                }
                if (pos.begin <= lastRequiredNonMaskPos) {
                    completed = isCompleted();
                }
            }
        }
        event.preventDefault();
    }
    updateModel(event);
    if (completed) {
        emit('complete', event);
    }
    emit('keypress', event);
}

function onPaste(event) {
    handleInputChange(event);
    emit('paste', event);
}

function seekNext(pos) {
    while (++pos < len && !tests[pos]);
    return pos;
}
function seekPrev(pos) {
    while (--pos >= 0 && !tests[pos]);
    return pos;
}
function shiftL(begin, end) {
    let i, j;
    if (begin < 0) { return; }
    for (i = begin, j = seekNext(end); i < len; i++) {
        if (tests[i]) {
            if (j < len && tests[i].test(buffer[j])) {
                buffer[i] = buffer[j];
                buffer[j] = getPlaceholder(j);
            } else {
                break;
            }
            j = seekNext(j);
        }
    }
    writeBuffer();
    caret(Math.max(firstNonMaskPos, begin));
}
function shiftR(pos) {
    let i, c, j, t;
    for (i = pos, c = getPlaceholder(pos); i < len; i++) {
        if (tests[i]) {
            j = seekNext(i);
            t = buffer[i];
            buffer[i] = c;
            if (j < len && tests[j].test(t)) {
                c = t;
            } else {
                break;
            }
        }
    }
}

function checkVal(allow) {
    isValueChecked.value = true;
    if (elRef.value == null) { return; }
    //try to place characters where they belong
    let test = elRef.value.value;
    let lastMatch = -1;
    let i, c, pos;
    for (i = 0, pos = 0; i < len; i++) {
        if (tests[i]) {
            buffer[i] = getPlaceholder(i);
            while (pos++ < test.length) {
                c = test.charAt(pos - 1);
                if (tests[i].test(c)) {
                    buffer[i] = c;
                    lastMatch = i;
                    break;
                }
            }
            if (pos > test.length) {
                clearBuffer(i + 1, len);
                break;
            }
        } else {
            if (buffer[i] === test.charAt(pos)) {
                pos++;
            }
            if (i < partialPosition) {
                lastMatch = i;
            }
        }
    }
    if (allow) {
        writeBuffer();
    } else if (lastMatch + 1 < partialPosition) {
        if (props.autoClear || buffer.join('') === defaultBuffer) {
            // Invalid value. Remove it and replace it with the
            // mask, which is the default behavior.
            if (elRef.value.value) {
                elRef.value.value = '';
            }
            clearBuffer(0, len);
        } else {
            // Invalid value, but we opt to show the value to the
            // user and allow them to correct their mistake.
            writeBuffer();
        }
    } else {
        writeBuffer();
        elRef.value.value = elRef.value.value.substring(0, lastMatch + 1);
    }
    return partialPosition ? i : firstNonMaskPos;
}

function getPlaceholder(i) {
    if (i < props.slotChar.length) {
        return props.slotChar.charAt(i);
    }
    return props.slotChar.charAt(0);
}

function clearBuffer(start, end) {
    let i;
    for (i = start; i < end && i < len; i++) {
        if (tests[i]) {
            buffer[i] = getPlaceholder(i);
        }
    }
}

function writeBuffer() {
    elRef.value.value = buffer.join('');
}

function caret(first, last) {
    let range, begin, end;
    if (!elRef.value.offsetParent || elRef.value !== document.activeElement) {
        return;
    }
    if (typeof first === 'number') {
        begin = first;
        end = typeof last === 'number' ? last : begin;
        if (elRef.value.setSelectionRange) {
            elRef.value.setSelectionRange(begin, end);
        } else if (elRef.value['createTextRange']) {
            range = elRef.value['createTextRange']();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', begin);
            range.select();
        }
    } else {
        if (elRef.value.setSelectionRange) {
            begin = elRef.value.selectionStart;
            end = elRef.value.selectionEnd;
        } else if (document['selection'] && document['selection'].createRange) {
            range = document['selection'].createRange();
            begin = 0 - range.duplicate().moveStart('character', -100000);
            end = begin + range.text.length;
        }
        return { begin: begin, end: end };
    }
}

function handleAndroidInput(event) {
    let curVal = elRef.value.value;
    let pos = caret();
    if (oldVal && oldVal.length && oldVal.length > curVal.length) {
        // a deletion or backspace happened
        checkVal(true);
        while (pos.begin > 0 && !tests[pos.begin - 1]) pos.begin--;
        if (pos.begin === 0) {
            while (pos.begin < firstNonMaskPos && !tests[pos.begin]) pos.begin++;
        }
        caret(pos.begin, pos.begin);
    } else {
        checkVal(true);
        while (pos.begin < len && !tests[pos.begin]) pos.begin++;
        caret(pos.begin, pos.begin);
    }
    if (isCompleted()) {
        emit('complete', event);
    }
}

function handleInputChange(event) {
    if (props.readonly) {
        return;
    }
    let pos = checkVal(true);
    caret(pos);
    updateModel(event);
    if (isCompleted()) {
        emit('complete', event);
    }
}

function isCompleted() {
    for (let i = firstNonMaskPos; i <= lastRequiredNonMaskPos; i++) {
        if (tests[i] && buffer[i] === getPlaceholder(i)) {
            return false;
        }
    }
    return true;
}

function isValueUpdated() {
    return props.unmask ? props.modelValue != getUnmaskedValue() : defaultBuffer !== elRef.value.value && elRef.value.value !== props.modelValue;
}

function updateValue(updateModel = true) {
    if (elRef.value) {
        if (props.modelValue == null) {
            elRef.value.value = '';
            if (updateModel) { emit('update:modelValue', ''); }
        } else {
            elRef.value.value = props.modelValue;
            checkVal();
            setTimeout(() => {
                if (elRef.value) {
                    writeBuffer();
                    checkVal();
                    if (updateModel) {
                        let val = props.unmask ? getUnmaskedValue() : elRef.value.value;
                        emit('update:modelValue', defaultBuffer !== val ? val : '');
                    }
                }
            }, 10);
        }
        focusText = elRef.value.value;
    }
}

function updateModel(e) {
    let val = props.unmask ? getUnmaskedValue() : e.target.value;
    emit('update:modelValue', defaultBuffer !== val ? val : '');
}

function normalizeModel(e) {
    let val = props.unmask ? getUnmaskedValue() : e.target.value;
    if (!val || defaultBuffer === val) {
        emit('update:modelValue','');
    } else {
        let normalizedValue = '';
        buffer.some((char, i) => {
            const placeholder = getPlaceholder(i);
            if (placeholder === char) {
                return true;
            } else {
                normalizedValue += char;
                return false;
            }
        });
        e.target.value = normalizedValue;
        emit('update:modelValue', normalizedValue);
    }
}

function getUnmaskedValue() {
    let unmaskedBuffer = [];
    for (let i = 0; i < buffer.length; i++) {
        let c = buffer[i];
        if (tests[i] && c !== getPlaceholder(i)) {
            unmaskedBuffer.push(c);
        }
    }
    return unmaskedBuffer.join('');
}

function positionIsFilled(index) {
    const placeholder = getPlaceholder(index);
    return buffer[index] !== placeholder;
}

// Computed props
const filled = computed(() => {
    return props.modelValue != null && props.modelValue.toString().length > 0;
});

const inputClass = computed(() => {
    return ['o365-inputmask', {
        'o365-inputmask-filled': filled.value
    }];
});

function isFilled() {
    return buffer.every((bufferValue, index) => {
        const placeholder = getPlaceholder(index);
        if (bufferValue !== placeholder) {
            return true;
        } else {
            return false;
        }
    });
}

// Lifecycle hooks
onMounted(() => {
    tests = [];
    partialPosition = props.mask.replace(/\\(.)/g, '$1').length;
    len = props.mask.length;
    defs = {
        9: '[0-9]',
        a: '[A-Za-z]',
        '*': '[A-Za-z0-9]'
    };
    let ua = DomHandler.getUserAgent();
    androidChrome = /chrome/i.test(ua) && /android/i.test(ua);
    let maskTokens = props.mask.split('');
    let escapeNext = false;
    for (let i = 0; i < maskTokens.length; i++) {
        let c = maskTokens[i];
        if (escapeNext) {
            tests.push(null);
            escapeNext = false;
            maskTokens[i] = `\\${maskTokens[i]}`;
            maskTokens.splice(i-1,1);
            i--;
        } else if (c === '\\') {
            len--;
            escapeNext = true;
        } else if (c === '?') {
            len--;
            partialPosition = i;
        } else if (defs[c]) {
            tests.push(new RegExp(defs[c]));
            if (firstNonMaskPos === null) {
                firstNonMaskPos = tests.length - 1;
            }
            if (i < partialPosition) {
                lastRequiredNonMaskPos = tests.length - 1;
            }
        } else {
            tests.push(null);
        }
    }
    buffer = [];
    for (let i = 0; i < maskTokens.length; i++) {
        let c = maskTokens[i];
        if (c !== '?') {
            if (defs[c]) {
                buffer.push(getPlaceholder(i));
            } else {
                if (c.length > 1) { c = c.charAt(1); }
                buffer.push(c);
            }
        }
    }
    defaultBuffer = buffer.join('');
    updateValue(false);
});

onUpdated(() => {
    if (!props.skipUpdates && isValueUpdated()) {
        updateValue();
    }
});

defineExpose({ elRef, focus, isValueChecked, positionIsFilled, checkVal, isFilled });

</script>